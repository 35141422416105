<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="box_9 flex-row justify-between">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng161aab0d37acaebc7e9b25978bb700ff1759e6eef0785bbfa7bbc45e9778180d.png"
        />
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc9e225ad9d5574121d862676ea148ab080970bea298be589dd5a92e48a8151e3.png"
        />
        <div class="section_4 flex-col justify-between">
          <div class="image-wrapper_1 flex-col">
            <img
              class="thumbnail_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngb39abf30e2a640b3533fa2a1ce0dbc1af413973d9ad4724e9952cea532bf01ec.png"
            />
          </div>
          <div class="image-wrapper_10 flex-row justify-between">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng7da3db337121717c9a78c4b4bc3fd1a3a5435056a2713eb851f3f9c2ee323a83.png"
            />
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngcb598b9af3251c2120c8e795aea0677f4b0c2dd83d5c2ac44342c41b1935d942.png"
            />
          </div>
        </div>
      </div>
      <span class="text_1">
        Meet,&nbsp;Match,&nbsp;Date&nbsp;young&nbsp;people&nbsp;all<br/>over&nbsp;the&nbsp;world
      </span>
      <span class="text_2">
        Hoya&nbsp;is&nbsp;a&nbsp;free&nbsp;dating&nbsp;app&nbsp;for&nbsp;young&nbsp;people&nbsp;specifically&nbsp;for<br/>Gen&nbsp;Z,&nbsp;to&nbsp;make&nbsp;new&nbsp;friends,&nbsp;meet,&nbsp;match,&nbsp;chat&nbsp;and&nbsp;date<br/>young&nbsp;adults&nbsp;and&nbsp;teens
      </span>
      <div class="box_10 flex-row justify-between">
        <a href="https://apps.apple.com/us/app/hoya-dating-young-people/id1590728083" target="_blank">
        <div class="box_2 flex-row">
          <div class="image-text_5 flex-row justify-between">
            <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9216c254f9998bd1b6cb4af1a8c0b5e0b2acef984f7ce8eacf01824e1733f5d9.png"
            />
            <span class="text-group_1">App&nbsp;Store</span>
          </div>
        </div></a>
        <a href="https://play.google.com/store/apps/details?id=com.shierke.hoya" target="_blank">
        <div class="box_3 flex-row">
          <div class="image-text_6 flex-row justify-between">
            <img
              class="label_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng29973eb91aad8ccff358ae368b407315712cd9d9ad36a9336729c24529ad67fc.png"
            />
            <span class="text-group_2">Google&nbsp;Play</span>
          </div>
        </div></a>
      </div>
      <div class="section_3 flex-row justify-end">
        <div :class="{ 'sticky': isSticky }" class="sticky-element">
        <!-- 导航栏内容 -->
        <span class="text_3">Download&nbsp;for&nbsp;FREE</span>
        <a href="https://apps.apple.com/us/app/hoya-dating-young-people/id1590728083" target="_blank">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8eeb20ba9f6db1894911ceb143b2b873d440cbd837b61a12ec4ab6b03dc702a5.png"
        /></a>
        <a href="https://play.google.com/store/apps/details?id=com.shierke.hoya" target="_blank">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng12093b0f9dc2faa3673151ce6191082902b0844d9fa40410e9d660429db8455e.png"
        /></a>
        </div>
      </div>
    </div>
    <div class="group_2 flex-row justify-between">
      <div class="image-wrapper_3 flex-col">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/d8f9094d680c4ebf8f37d2334c59b496_mergeImage.png"
        />
      </div>
      <div class="text-group_13 flex-col justify-between">
        <div class="text-wrapper_1">
          <span class="text_4">Special&nbsp;Dating&nbsp;platform<br/>for</span>
          <span class="text_5">&nbsp;Gen-Z</span>
        </div>
        <span class="text_6">
          Where&nbsp;young&nbsp;hearts&nbsp;connect,&nbsp;sparks&nbsp;fly,&nbsp;and&nbsp;unforgettable<br/>connections&nbsp;are&nbsp;made.&nbsp;Join&nbsp;our&nbsp;vibrant&nbsp;community&nbsp;of&nbsp;like-<br/>minded&nbsp;Gen-Z&nbsp;individuals&nbsp;seeking&nbsp;meaningful&nbsp;relationships,<br/>exciting&nbsp;adventures,&nbsp;and&nbsp;shared&nbsp;passions.&nbsp;Experience&nbsp;a<br/>modern&nbsp;and&nbsp;inclusive&nbsp;dating&nbsp;experience&nbsp;tailored&nbsp;to&nbsp;the<br/>unique&nbsp;needs&nbsp;and&nbsp;preferences&nbsp;of&nbsp;the&nbsp;new&nbsp;generation.&nbsp;Get<br/>ready&nbsp;to&nbsp;swipe,&nbsp;chat,&nbsp;and&nbsp;discover&nbsp;your&nbsp;perfect&nbsp;match&nbsp;in&nbsp;this<br/>dynamic&nbsp;dating&nbsp;world!
        </span>
      </div>
    </div>
    <div class="box_11 flex-col">
      <div class="section_5 flex-row justify-between">
        <div class="text-group_14 flex-col justify-between">
          <div class="text-wrapper_2">
            <span class="text_7">Interest?</span>
            <span class="text_8">&nbsp;Swipe&nbsp;RIGHT</span>
            <span class="paragraph_1">
              <br />
              to&nbsp;like&nbsp;and&nbsp;match
            </span>
          </div>
          <span class="text_9">
            Explore&nbsp;a&nbsp;world&nbsp;of&nbsp;shared&nbsp;interests&nbsp;and&nbsp;endless&nbsp;possibilities.<br/>With&nbsp;a&nbsp;simple&nbsp;swipe&nbsp;to&nbsp;the&nbsp;right,&nbsp;discover&nbsp;like-minded<br/>individuals&nbsp;who&nbsp;share&nbsp;your&nbsp;passions&nbsp;and&nbsp;hobbies.&nbsp;Whether<br/>it's&nbsp;music,&nbsp;art,&nbsp;sports,&nbsp;or&nbsp;more,&nbsp;our&nbsp;platform&nbsp;connects&nbsp;you<br/>with&nbsp;compatible&nbsp;souls&nbsp;who&nbsp;ignite&nbsp;your&nbsp;curiosity&nbsp;and&nbsp;fuel&nbsp;your<br/>excitement.&nbsp;Embrace&nbsp;the&nbsp;thrill&nbsp;of&nbsp;connecting&nbsp;with&nbsp;someone<br/>who&nbsp;gets&nbsp;you.&nbsp;Say&nbsp;goodbye&nbsp;to&nbsp;endless&nbsp;scrolling&nbsp;and&nbsp;hello&nbsp;to<br/>meaningful&nbsp;connections.&nbsp;Swipe&nbsp;right&nbsp;to&nbsp;unlock&nbsp;a&nbsp;world&nbsp;of<br/>possibilities&nbsp;and&nbsp;start&nbsp;your&nbsp;exciting&nbsp;journey&nbsp;towards&nbsp;finding<br/>your&nbsp;perfect&nbsp;match.
          </span>
        </div>
        <div class="group_4 flex-col">
          <div class="group_5 flex-col">
            <div class="image-wrapper_11 flex-row">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng822d22fd539464b811f9f00a2e922d22e757f8f6897019b571db273fdffff0cc.png"
              />
            </div>
            <div class="image-wrapper_12 flex-row">
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng7e3a3ba88c6c629988e794c498f5d8bc61e595bc19d91460e8e4432862f8dbdb.png"
              />
            </div>
            <div class="box_4 flex-row">
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngac07db0b72b5ef74290e34385c1b98a010bfaf2d2d16237329cec897eb1e830f.png"
              />
              <div class="image-wrapper_6 flex-col">
                <img
                  class="image_8"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng824198a20faa4903f6224b2cc2104661bcd15bff1b6b82ec4a327ba4ed680af2.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group_6 flex-row">
      <div class="text-group_15 flex-col justify-between">
        <div class="text-wrapper_3">
          <span class="text_10">Match,&nbsp;Chat&nbsp;and&nbsp;Date<br/>your&nbsp;new</span>
          <span class="text_11">&nbsp;BFF</span>
        </div>
        <span class="text_12">
          Discover&nbsp;the&nbsp;perfect&nbsp;BFF&nbsp;you've&nbsp;been&nbsp;searching&nbsp;for!&nbsp;Our<br/>platform&nbsp;goes&nbsp;beyond&nbsp;traditional&nbsp;dating&nbsp;to&nbsp;help&nbsp;you&nbsp;find<br/>genuine&nbsp;friendships&nbsp;that&nbsp;last&nbsp;a&nbsp;lifetime.&nbsp;Swipe&nbsp;and&nbsp;match&nbsp;with<br/>like-minded&nbsp;individuals&nbsp;who&nbsp;share&nbsp;your&nbsp;interests,&nbsp;values,&nbsp;and<br/>zest&nbsp;for&nbsp;life.&nbsp;Engage&nbsp;in&nbsp;meaningful&nbsp;conversations,&nbsp;build<br/>connections,&nbsp;and&nbsp;create&nbsp;beautiful&nbsp;memories&nbsp;together.<br/>Whether&nbsp;you're&nbsp;looking&nbsp;for&nbsp;a&nbsp;coffee&nbsp;buddy,&nbsp;workout&nbsp;partner,<br/>or&nbsp;someone&nbsp;to&nbsp;explore&nbsp;new&nbsp;adventures&nbsp;with,&nbsp;our&nbsp;platform&nbsp;is<br/>your&nbsp;gateway&nbsp;to&nbsp;finding&nbsp;your&nbsp;new&nbsp;BFF.&nbsp;Chat,&nbsp;laugh,&nbsp;and<br/>embark&nbsp;on&nbsp;exciting&nbsp;journeys&nbsp;with&nbsp;your&nbsp;newfound&nbsp;friend.&nbsp;Start<br/>swiping&nbsp;now&nbsp;and&nbsp;let&nbsp;the&nbsp;magic&nbsp;of&nbsp;friendship&nbsp;unfold!
        </span>
      </div>
      <div class="box_5 flex-col"></div>
    </div>
    <div class="box_12 flex-col">
      <div class="text-group_16 flex-col justify-between">
        <span class="text_13">How&nbsp;Hoya&nbsp;dating&nbsp;works?</span>
        <span class="text_14">
          With&nbsp;the&nbsp;following&nbsp;steps,&nbsp;Start&nbsp;your&nbsp;wonderful&nbsp;dating&nbsp;journey
        </span>
      </div>
      <div class="section_6 flex-row justify-between">
        <div class="block_2 flex-col">
          <div class="text-group_17 flex-col justify-between">
            <span class="text_15">Step&nbsp;1</span>
            <span class="text_16">Download&nbsp;Hoya&nbsp;App</span>
          </div>
          <a href="https://apps.apple.com/us/app/hoya-dating-young-people/id1590728083" target="_blank">
          <div class="block_3 flex-row">
            <div class="image-text_7 flex-row justify-between">
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng9216c254f9998bd1b6cb4af1a8c0b5e0b2acef984f7ce8eacf01824e1733f5d9.png"
              />
              <span class="text-group_8">App&nbsp;Store</span>
            </div>
          </div></a>
          <a href="https://play.google.com/store/apps/details?id=com.shierke.hoya" target="_blank">
          <div class="block_4 flex-row">
            <div class="image-text_8 flex-row justify-between">
              <img
                class="label_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng29973eb91aad8ccff358ae368b407315712cd9d9ad36a9336729c24529ad67fc.png"
              />
              <span class="text-group_9">Google&nbsp;Play</span>
            </div>
          </div></a>
        </div>
        <div class="block_5 flex-col justify-end">
          <div class="text-group_18 flex-col justify-between">
            <span class="text_17">Step&nbsp;2</span>
            <span class="text_18">Create&nbsp;a&nbsp;Profile</span>
          </div>
          <img
            class="image_9"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng3d9e0f44262b0be89fe3a9a145c7c47fdac3cd6f2f6f2f4007249b44c7e4e6bd.png"
          />
        </div>
        <div class="block_6 flex-col">
          <div class="text-group_19 flex-col justify-between">
            <span class="text_19">Step&nbsp;3</span>
            <span class="text_20">Looking&nbsp;for&nbsp;young&nbsp;people&nbsp;nearby</span>
          </div>
          <div class="image-wrapper_13 flex-row justify-between">
            <img
              class="image_10"
              referrerpolicy="no-referrer"
              src="./assets/img/11c568e78033402f945469038c195134_mergeImage.png"
            />
            <img
              class="image_11"
              referrerpolicy="no-referrer"
              src="./assets/img/5eb93e25f1e4415b94c6e9eee30edb3a_mergeImage.png"
            />
          </div>
          <img
            class="image_12"
            referrerpolicy="no-referrer"
            src="./assets/img/4a747302d65f4afb912c79e263d6ff02_mergeImage.png"
          />
        </div>
        <div class="block_7 flex-col">
          <div class="text-group_20 flex-col justify-between">
            <span class="text_21">Step&nbsp;4</span>
            <span class="text_22">Match&nbsp;and&nbsp;start&nbsp;your&nbsp;dating&nbsp;journey</span>
          </div>
          <img
            class="image_13"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngbfae2d055a21c8146af0e86f43969a9deb4faf86ab555798cd8852f52083d233.png"
          />
        </div>
      </div>
      <div class="box_7 flex-col">
        <div class="box_13 flex-row">
          <img
            class="label_7 link-with-cursor" @click="handleLink1('#')"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng87b8a46ee790c14df91e024992722c4318c2dd5026ebf331f33ac59c3227517b.png"
          />
          <img
            class="thumbnail_3 link-with-cursor" @click="handleLink1('#')"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnga6503d2d18da5b8b5546d8a4fe3e99ba0b1b4beabec39a6fd1d3fe0ac33a188f.png"
          />
          <div class="section_7 flex-col justify-between link-with-cursor" @click="handleLink1('#')">
            <div class="image-wrapper_8 flex-col">
              <img
                class="thumbnail_4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngadd84f7cdcd3a8599b8c07543484e5c0ec9bc8215f12b64b5b592713368c0196.png"
              />
            </div>
            <div class="image-wrapper_14 flex-row justify-between">
              <img
                class="image_14"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPnga9c06f49a293a121653e4af8587df5eb826f3b527d5ebd79f725af97b5f6b227.png"
              />
              <img
                class="thumbnail_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng82ebd5d9899cd25abf638f1e744c1ce271d9f0403322f9110b95c037d73dbac8.png"
              />
            </div>
          </div>
          <span class="text_23 link-with-cursor" @click="handleLink1('https://static.hoyachat.com/Privacy-Policy.html')">Privacy&nbsp;Policy</span>
          <span class="text_24 link-with-cursor" @click="handleLink1('#')">US&nbsp;Dating</span>
          <span class="text_25 link-with-cursor" @click="handleLink1('#')">Teen&nbsp;Dating</span>
        </div>
        <div class="text-wrapper_8 flex-row">
          <span class="text_26">©&nbsp;2023&nbsp;Hoya.&nbsp;All&nbsp;right&nbsp;reserved</span>
          <span class="text_27 link-with-cursor" @click="handleLink1('https://static.hoyachat.com/Terms-of-Service.html')">Terms&nbsp;of&nbsp;Service</span>
          <span class="text_28 link-with-cursor" @click="handleLink1('#')">UK&nbsp;Dating</span>
          <span class="text_29 link-with-cursor" @click="handleLink1('#')">Gay&nbsp;Dating</span>
        </div>
        <div class="text-wrapper_9 flex-row">
          <span class="text_30 link-with-cursor" @click="handleLink1('mailto:hoyachat2021@gmail.com')">Contact</span>
          <span class="text_31 link-with-cursor" @click="handleLink1('#')">Canada&nbsp;Dating</span>
          <span class="text_32 link-with-cursor" @click="handleLink1('#')">Lesbian&nbsp;Dating</span>
        </div>
        <div class="text-wrapper_10 flex-row">
          <span class="text_33 link-with-cursor" @click="handleLink1('mailto:hoyachat2021@gmail.com')">Support</span>
          <span class="text_34 link-with-cursor" @click="handleLink1('#')">Australia&nbsp;Dating</span>
          <span class="text_35 link-with-cursor" @click="handleLink1('#')">LGPTQ+&nbsp;Dating</span>
        </div>
        <div class="text-wrapper_11 flex-row"><span class="text_36 link-with-cursor" @click="handleLink1('#')">Portugal&nbsp;Dating</span></div>
        <div class="box_14 flex-row">
          <img @click="handleLink1('https://www.facebook.com/hoyachat')"
            class="thumbnail_6 link-with-cursor"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngbec2311f478edeb9df86fd82ab3d1dc17ecb049f9a9a1bddf03d7a07f8fbfe94.png"
          />
          <img @click="handleLink1('https://twitter.com/hoyachat')"
            class="thumbnail_7 link-with-cursor"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng6a052b4bca1b2bdba4afc7c4bad35c0b7411a592c6854b4f98b95d26a5ff4b0c.png"
          />
          <img @click="handleLink1('https://www.instagram.com/hoyachat2024/')"
            class="thumbnail_8 link-with-cursor"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng2d98ce05e8359dca959cc4782ec036395424ea1a142175167e5dd855e5af21e5.png"
          />
          <img @click="handleLink1('https://www.tiktok.com/@hoyachat')"
            class="thumbnail_9 link-with-cursor"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng3e948fbffdf100e6f96f6fb318d4cb97f02ac112f0fd15ffa3b6601e4ada27f5.png"
          />
          <span class="text_37 link-with-cursor" @click="handleLink1('#')">Spain&nbsp;Dating</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      isSticky: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
        const scrollThreshold = 200; // 滚动阈值，根据需要进行调整
        this.isSticky = window.pageYOffset > scrollThreshold;
    },
    handleLink1(url){
        if(url=='#'){
            location.href=url
        }else {
            window.open(url, '_blank');
        }
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
<style>
.sticky-element {
  position: fixed;
  z-index: 1;
  /* top: 0;
  right: 0; */
  /* width: 200px; 根据需要进行调整 */
  /* 添加其他样式 */
}
.link-with-cursor {
  cursor: pointer;
}
</style>